import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";
import chroma from "chroma-js";
import { createMuiTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialTextField from "@material-ui/core/TextField";
import MaterialSelect from "@material-ui/core/Select";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import { DatePicker as MaterialDatePicker } from "@material-ui/pickers";
import MaterialSlider from "@material-ui/core/Slider";

import bgBannerRight from "./assets/bg-purple-top.png";
import bgBannerLeft from "./assets/bg-banner-top.png";
import bgQuote from "./assets/bg-blockquote.svg";

export const campaignMenuHeight = "68px";
export const mobileCampaignMenuHeight = "94px";
// export const campaignMenuHeight = "0px";
// export const mobileCampaignMenuHeight = "0px";
export const menuHeight = "88px";
export const mobileMenuHeight = "60px";
export const menuOffset =
  parseInt(campaignMenuHeight, 10) + parseInt(menuHeight, 10) + "px";
export const mobileMenuOffset =
  parseInt(mobileCampaignMenuHeight, 10) +
  parseInt(mobileMenuHeight, 10) +
  "px";

export const colors = {
  textColor: "#4C4C4C",
  lightTextColor: "#808080",
  borderColor: "#C3C9C9",
  titleBorder: "#DEDEDE",
  primaryColor: "#E93A56", // red
  primaryHover: "#9164A0", // light purple
  dateColor: "#69BF98", // green
  authorColor: "#6E48AC", // purple
  programColor: "#2AB0EA", // blue,
  btnShadow: "#ead6f3",
  menuTitleColor: "#561c7c", // purple
  gray: "#505d65",
  lightGray: "#DDEEF5",
  red: "#E93A56",
  purple: "#6E48AC",
  lightPurple: "#9164A0",
  blue: "#2AB0EA",
  blueGray: "#c0dae8",
  darkBlue: "#002f80",
  green: "#69BF98",
  yellow: "#FFB857",
};

export const gradients = {
  purpleToPink: (direction = "to right") =>
    `linear-gradient(${direction}, #A222CC, #FF4360)`,
  redToPurple: (direction = "to right") =>
    `linear-gradient(${direction}, #760129, #361029 20%, #29155a)`,
  toDarkPurple: (direction = "to right") => `linear-gradient(
      ${direction},
      rgba(16, 0, 43, 0) 40%,
      rgba(16, 0, 43, 0.45) 72%,
      rgba(14, 2, 34, 0.85) 110%
    )`,
  greyToPink: (direction = "to right") =>
    `linear-gradient(${direction}, ${colors.blueGray}, #DBCCEE)`,
  darkGreyToPink: (direction = "to right") =>
    `linear-gradient(${direction}, #6F8DA7, #B5A9C6)`,
};

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const lightFadeIn = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
`;
export const FadeInPage = styled.div`
  animation: ${lightFadeIn} 0.5s linear;
`;

const fromTop = keyframes`
  from {
    transform: translateY(-600px);
  }

  to {
    transform: translateY(0);
  }
`;
export const FromTopPage = styled.div`
  animation: ${fromTop} 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const MainWrapper = styled.div`
  margin: 0 auto;
  width: 1600px;

  @media (max-width: 1599px) {
    width: 1024px;
  }

  @media (max-width: 1023px) {
    width: 768px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const GridWrapper = styled.div`
  margin: 0 auto;
  width: 1174px;

  @media (max-width: 1599px) {
    width: 890px;
  }

  @media (max-width: 1023px) {
    width: 595px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 52px);
  }
`;

export const Banner = styled.header`
  background: url(${bgBannerLeft}) center left no-repeat,
    url(${bgBannerRight}) bottom right no-repeat, ${gradients.redToPurple()};
  color: white;

  @media (max-width: 1599px) {
    background-size: 25%, 50%, auto;
  }

  @media (max-width: 767px) {
    background-size: 0, 90%, auto;
  }

  > div {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 150px 0 56px;
    position: relative;
    z-index: 1;

    @media (max-width: 1023px) {
      display: block;
      padding: 56px 0 56px;
      text-align: center;
    }
  }

  h1 {
    font-size: 8.2rem;
    font-weight: 600;
    letter-spacing: 0.34rem;
    margin-bottom: 5px;
    text-transform: uppercase;
    word-break: break-word;

    @media (max-width: 767px) {
      font-size: 4.2rem;
      letter-spacing: 0.16rem;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 2rem;

    @media (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  > div > a {
    font-size: 1.5rem;
    margin-bottom: -15px;
    padding: 14px 40px;

    @media (max-width: 1023px) {
      margin: 30px auto 0;
    }
  }
`;

export const Heading = styled.header`
  margin-bottom: 52px;

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  h1 {
    color: black;
    font-size: 4.2rem;
    font-weight: 100;
    letter-spacing: -0.07rem;
    margin-bottom: 13px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 3.4rem;
    }
  }

  p {
    margin: 0;
    padding: 0 15px;
    text-align: center;
  }
`;

export const Button = styled.button`
  background: ${(props) => props.background || gradients.purpleToPink()};
  color: white !important;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 600;
  left: 0;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.15s linear;
  top: 0;

  :before,
  :after {
    background: ${colors.btnShadow};
    content: "";
    display: block;
    height: 6px;
    opacity: 0;
    position: absolute;
    transform: rotate(45deg);
    transition: 0.15s linear;
    width: 6px;
    z-index: -1;
  }

  :before {
    bottom: 1px;
    left: 1px;
  }

  :after {
    top: 1px;
    right: 1px;
  }

  :hover {
    box-shadow: 4px 4px 0 ${colors.btnShadow};
    top: -4px;
    left: -4px;

    :before,
    :after {
      opacity: 1;
    }

    :before {
      bottom: -3px;
      left: 1px;
    }

    :after {
      top: 1px;
      right: -3px;
    }
  }
`;

export const Date = styled.time`
  background: ${colors.dateColor};
  border-bottom-right-radius: 5px;
  color: black;
  font-size: 1.4rem;
  padding: 1px 10px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const Quote = styled.blockquote`
  background: url(${bgQuote}) no-repeat left top;
  color: ${colors.primaryColor};
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 3rem;
  margin: 0;
  min-height: 155px;
  padding: 32px 67px 32px 85px;
`;

export const SmallFields = styled.div`
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }

  fieldset {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const TextField = (props) => <MaterialTextField {...props} />;

export const Select = ({
  children,
  error,
  helperText,
  label,
  onChange,
  required,
  value,
}) => (
  <FormControl error={error}>
    {label && <InputLabel required={required}>{label}</InputLabel>}
    <MaterialSelect value={value} onChange={onChange}>
      {children}
    </MaterialSelect>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);
Select.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

export const Checkbox = ({
  error,
  helperText,
  label,
  onChange,
  required,
  value,
}) => (
  <FormControl error={error}>
    <FormControlLabel
      label={label}
      control={
        <MaterialCheckbox
          color="primary"
          value={value}
          onChange={(e) => onChange(e.target.checked)}
          required={required}
        />
      }
    />
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);
Checkbox.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.bool,
};

export const BirthdayPicker = (props) => (
  <MaterialDatePicker
    autoOk
    disableFuture
    disableToolbar
    openTo="year"
    format="dd/MM/yyyy"
    label="Date de naissance"
    variant="inline"
    views={["year", "month", "date"]}
    {...props}
  />
);

export const Slider = (props) => <MaterialSlider {...props} />;

export const RadioField = styled.fieldset`
  margin-top: 20px;

  input {
    position: absolute;
    left: -9999px;
  }

  input + label {
    border: 1px solid ${colors.purple};
    color: ${colors.purple};
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 1px;
    padding: 12px 24px;

    :last-of-type {
      border-top-right-radius: 10px;
    }

    @media (max-width: 767px) {
      margin: 1px;

      :last-of-type {
        border-top-right-radius: 0;
      }
    }
  }

  input:disabled + label {
    border: 1px solid ${colors.borderColor};
    color: ${colors.borderColor};
    cursor: not-allowed;
  }

  input:not(:disabled) + label:hover,
  input:checked + label {
    background: ${colors.purple};
    border-color: ${colors.purple};
    color: white;
  }
`;

const spin = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;
export const Spinner = styled.div`
  color: ${(props) => props.color || colors.purple};
  font-size: 10px;
  margin: 80px auto;
  position: relative;

  &,
  :before,
  :after {
    animation: ${spin} 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    text-indent: -9999em;
    width: 2.5rem;
    height: 2.5rem;
  }

  & {
    animation-delay: -0.16s;
  }

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
  }

  :before {
    animation-delay: -0.32s;
    left: -4.5rem;
  }

  :after {
    left: 4.5rem;
  }
`;

export const LegendColor = styled.span`
  background: ${(props) => props.color};
  border-radius: 4px;
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  vertical-align: -2px;
  width: 12px;

  @media (max-width: 767px) {
    margin-right: 1px;
  }
`;

export const muiDefaultTheme = createMuiTheme({
  typography: {
    fontFamily: "Geomanist, sans-serif",
  },
  palette: {
    primary: { main: colors.purple },
    error: { main: colors.red },
    text: { primary: colors.textColor },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "flex-start",
        marginLeft: 0,
        marginRight: 0,
        "& + .MuiFormHelperText-root": {
          fontSize: "1.5rem",
          marginBottom: "15px",
        },
      },
      label: {
        fontSize: "1.5rem",
        paddingTop: "8px",
        "& a": {
          color: colors.purple,
          whiteSpace: "nowrap",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: chroma(colors.textColor).alpha(0.5).css(),
        fontSize: "1.5rem",
        fontWeight: 500,
        textTransform: "uppercase",
        "&$focused": {
          fontWeight: 600,
        },
        zIndex: 1,
      },
      filled: {
        color: colors.purple,
        fontWeight: 600,
      },
      asterisk: {
        color: colors.purple,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.5rem",
        fontWeight: 500,
      },
      input: {
        padding: "10px 0",
      },
      multiline: {
        backgroundColor: "#fcfcfc",
      },
    },
    MuiInput: {
      root: {
        "&$error .MuiSelect-icon": {
          color: colors.red,
        },
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${chroma(colors.purple).alpha(0.3).css()}`,
        },
        "&:hover&:not(.Mui-disabled)&:before": {
          borderBottom: `2px solid ${colors.purple}`,
        },
      },
    },
    MuiSelect: {
      select: {
        textAlign: "left",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      icon: {
        color: colors.purple,
        fontSize: "2rem",
        top: "calc(50% - 10px)",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "& .MuiSvgIcon-root": {
          color: colors.purple,
          fontSize: "1.9rem",
        },
      },
    },
    MuiRadio: {
      root: {
        padding: "8px",
        margin: "4px 4px -8px -8px",
      },
      colorSecondary: {
        "&$checked": {
          color: colors.purple,
        },
      },
    },
    // DatePicker :
    MuiTypography: {
      h5: {
        fontSize: "1.8rem",
      },
      subtitle1: {
        fontSize: "1.3rem",
      },
      body1: {
        fontSize: "1.5rem",
      },
      body2: {
        fontSize: "1.3rem",
      },
      caption: {
        fontSize: "1.3rem",
      },
    },
    MuiSlider: {
      track: {
        borderRadius: "2px",
        height: "3px",
      },
      thumb: {
        "&$focusVisible, &:hover": {
          boxShadow: `0 0 0 6px ${chroma(colors.purple).alpha(0.16).css()}`,
        },
        "&$active, &$active:hover": {
          boxShadow: `0 0 0 10px ${chroma(colors.purple).alpha(0.16).css()}`,
        },
      },
      valueLabel: {
        color: "transparent",
        fontSize: "1.4rem",
        fontWeight: 500,
        top: "20px",
        transform: "none !important",
        "& span": {
          height: "auto",
          transform: "none",
          width: "auto",
          "& span": {
            color: colors.textColor,
            whiteSpace: "nowrap",
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: chroma(colors.lightTextColor).alpha(0.55).css(),
        fontSize: "1.2rem",
        lineHeight: "2.2rem",
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: "0 4px 11px rgba(0, 0, 0, 0.2)",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.3rem",
        fontWeight: 500,
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: colors.purple,
      },
    },
  },
});

export const useTabletLandscape = () =>
  useMediaQuery({ minWidth: 1024, maxWidth: 1599 });
export const useTabletPortrait = () =>
  useMediaQuery({ minWidth: 768, maxWidth: 1023 });
export const useMobile = () => useMediaQuery({ maxWidth: 767 });

export const useMinSmallDesktop = () => useMediaQuery({ minWidth: 1600 });
export const useMinTabletLandscape = () => useMediaQuery({ minWidth: 1024 });
export const useMaxTabletPortrait = () => useMediaQuery({ maxWidth: 1023 });
export const useMinTabletPortrait = () => useMediaQuery({ minWidth: 768 });
