import chroma from "chroma-js";
import numeral from "numeral";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { campaignMenuHeight, mobileCampaignMenuHeight } from "../../theme";

const Container = styled(Link)`
  background: ${chroma("#3A0F6E").alpha(0.95)};
  color: white;
  height: ${campaignMenuHeight};
  padding: 12px 0;
  text-align: center;
  position: fixed;
  text-decoration: none;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: 20000;

  &:hover {
    background: ${chroma("#6E0982").alpha(0.95)};
  }

  @media (max-width: 767px) {
    height: ${mobileCampaignMenuHeight};
  }

  .flex {
    align-items: center;
    display: flex;
    justify-content: center;

    .triangle {
      border-bottom: 9px solid transparent;
      border-top: 9px solid transparent;
      display: inline-block;
      height: 0;
      width: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 30000;

      &.left {
        border-left: 15px solid rgba(254, 217, 155, 0.8);
        transform: translateY(-50%) translateX(calc(-50% + -260px));
      }

      &.right {
        border-right: 15px solid rgba(254, 217, 155, 0.8);
        transform: translateY(-50%) translateX(calc(-50% + 260px));
      }

      @media (max-width: 767px) {
        &.left {
          transform: translateY(-50%) translateX(calc(-50% + -170px));
        }

        &.right {
          transform: translateY(-50%) translateX(calc(-50% + 170px));
        }
      }
    }

    > div {
      margin: 0 10px;

      @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 355px;
      }
    }
  }

  .first {
    font-size: 1.7rem;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .second {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 2px 0 6px;

    span {
      background: -webkit-linear-gradient(#ffe7c1 0%, #fed99b 60%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      letter-spacing: 0.08rem;
      text-transform: uppercase;
    }
  }
`;

function CampaignMenu() {
  const [amount, setAmount] = useState(10000);

  useEffect(() => {
    async function getAmount() {
      try {
        const res = await fetch("/donorbox");

        if (res.status === 200) {
          const { campaignAmount } = await res.json();
          setAmount(campaignAmount);
        }
      } catch (err) {
        console.log(err);
      }
    }

    getAmount();
  }, []);

  return (
    <Container to="/soutien">
      <div className="flex">
        <span className="triangle left" />
        <div>
          <div className="first">
            Soutenez la 1ère chaine d'info indépendante&nbsp;de&nbsp;France !
          </div>
          <div className="second">
            Déjà <span>{numeral(amount).format("0,0")} €</span> récoltés
          </div>
        </div>
        <span className="triangle right" />
      </div>
    </Container>
  );
}

export default CampaignMenu;
